import React, { useEffect, forwardRef, useImperativeHandle } from "react";
import MarkerClusterer from "@googlemaps/markerclustererplus";

let _markers = [];

let latLngVal;
let markerVal;


const MyMapComponent = (props, ref) => {
  //locations.push ({ lat: props.lat, lng: props.lon });


  let map = null;
    map = new window.google.maps.Map(document.getElementById("map"), {
      center: { lat: 41.03801, lng: 28.95198 },
      zoom: 15
    });

      const x = new window.google.maps.Marker({
        position: { lat: 41.03801, lng: 28.95198 },
        map: map,
        title:"Türkiye Denizcilik Zirvesi",
        id: 1,
        icon: require('./favicon.svg').default,

      });
      _markers.push(x)
  



  function geocode(request) {
    clear();
    geocoder
      .geocode(request)
      .then((result) => {
        const { results } = result;
        placeMarkerAndPanTo(results[0].geometry.location, map);
       // map.setCenter(results[0].geometry.location);
       // marker.setPosition(results[0].geometry.location);
      //  _markers.push(results[0].geometry.location)
       // marker.setMap(map);
       
        return results;
      })
      .catch((e) => {
        alert("Geocode was not successful for the following reason: " + e);
      });
  }

  function clear() {
    _markers.forEach((m) => {
      m.setMap(null);

    });
  }

  useImperativeHandle(ref, () => ({

    getLocation: () => {
      geocoder
        .geocode({ location: latLngVal })
        .then((response) => {
          if (response.results[0]) {
            elevator
              .getElevationForLocations({
                locations: [latLngVal],
              })
              .then(({ results }) => {
                infowindow.setPosition(latLngVal);
                // Retrieve the first result
                if (results[0]) {
                  var model = {
                    address: response.results[0].address_components,
                    elevation: results[0].elevation,
                    position: { lat: markerVal.getPosition().lat(), lng: markerVal.getPosition().lng() }
                  }
                  if (props.callBack) {
                    props.callBack(model)
                  }

                } else {
                  infowindow.setContent("No results found");
                }
              })
              .catch((e) =>
                infowindow.setContent("Elevation service failed due to: " + e)
              );

          } else {
            window.alert("No results found");
          }
        })
        .catch((e) => window.alert("Geocoder failed due to: " + e));



    }
  }));

  const geocoder = new window.google.maps.Geocoder();
  const elevator = new window.google.maps.ElevationService();
  const infowindow = new window.google.maps.InfoWindow({});
  infowindow.open(map);

  if (!props.disabledClick) {
    map.addListener("click", (e) => {
      placeMarkerAndPanTo(e.latLng, map);
    });

  }

  function placeMarkerAndPanTo(latLng, map) {
    clear();
    _markers = [];
    const marker = new window.google.maps.Marker({
      position: latLng,
      map: map,
    });
    _markers.push(marker)
    map.panTo(latLng);
    latLngVal = latLng;
    markerVal = marker;


  }

  const getLocation = () => {
    elevator
      .getElevationForLocations({
        locations: [this.latLng],
      })
      .then(({ results }) => {
        infowindow.setPosition(latLngVal);
        // Retrieve the first result
        if (results[0]) {
          // Open the infowindow indicating the elevation at the clicked position.
          infowindow.setContent(
            "The elevation at this point <br>is " +
            results[0].elevation +
            " meters." +
            "<p>Marker Location:" + markerVal.getPosition() + "</p>"
          );
        } else {
          infowindow.setContent("No results found");
        }
      })
      .catch((e) =>
        infowindow.setContent("Elevation service failed due to: " + e)
      );
  }
  // Create an array of alphabetical characters used to label the markers.
  const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  // Add some markers to the map.
  // Note: The code uses the JavaScript Array.prototype.map() method to
  // create an array of markers based on a given "locations" array.
  // The map() method here has nothing to do with the Google Maps API.
  // const markers =
  //   locations.map((location, i) => {
  //     const marker = new window.google.maps.Marker({
  //       position: location,
  //       label: labels[i % labels.length]
  //     });
  //     _markers.push(marker)
  //     return marker;
  //   });

  // Add a marker clusterer to manage the markers.
  // new MarkerClusterer(map, markers, {
  //   imagePath:
  //     "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
  // });

  return null;
};

export default forwardRef(MyMapComponent);

