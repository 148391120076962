import React from "react";
import Wrapper, { Status } from "./Wrapper";
import MyMapComponent from "./MyMapComponent";
import { Modal } from "antd";;

const renderContent = (status) => {
  if (status === Status.LOADING) return <p>Loading...</p>;
  if (status === Status.FAILURE) return <p>Error...</p>;
  return null;
};
export default class MapWrapper extends React.Component {
  // private scrollRef = React.createRef<HTMLDivElement>();
  mapRef;
  constructor(props) {
    super(props);
    this.state = { visible: this.props.visible || false }
  }
  componentDidUpdate() {
    if (this.state.visible != this.props.visible) {
      this.setState({ visible: this.props.visible })
    }
  }
  onOk = () => {
    if (this.mapRef) {
      this.mapRef.getLocation();
    }

  }
  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }
  render() {
    const { visible } = this.state;
    const { disabledClick } = this.props;
    
    var lat= this.props.lat;
    var lon= this.props.lon;

    if(this.props.inContent){
      return (
       
        <> 
          <Wrapper language="en" apiKey="AIzaSyCuQOxTcpyuSSVmiq8ce6HQWM4UDjbAg34" render={renderContent} >
            <MyMapComponent disabledClick={disabledClick} onMarkerClick={this.props.onMarkerClick}  markers={this.props.markers} lat={lat} lon={lon} ref={(e) => this.mapRef = e} callBack={(model) => {
              if (this.props.onOK) {
                this.props.onOK(model)
              }
            }} />
          </Wrapper>
          <div style={{ height: "160px" }} id="map"></div>
          </> )
    }
    return (<Modal
      width={800}
      title={"Harita"}
      onCancel={this.onCancel}
      onOk={this.onOk}
      visible={visible}>

          <Wrapper language="en" apiKey="AIzaSyCuQOxTcpyuSSVmiq8ce6HQWM4UDjbAg34" render={renderContent} >
            <MyMapComponent  markers={this.props.markers} lat={lat} lon={lon} ref={(e) => this.mapRef = e} callBack={(model) => {
              if (this.props.onOK) {
                this.props.onOK(model)
              }
            }} />
          </Wrapper>
          <div style={{ height: "400px" }} id="map"></div>
     
    </Modal>)

  }
}

