import { Row, Col } from 'antd';
import React ,{useEffect}from 'react';
import ButtonContainer from '../components/ButtonContainer';
import './index.css'

export default function AboutUs({ history }) {

    
    useEffect(() => {
        window.scrollTo(0,0);;
    },[]);
    return (
        <Row type="flex" >

            <Col span={24} >
                <img src={require("../assets/images/banner.png")} className="header-banner" alt='header-banner' />
                <div className="banner-content">
                    HAKKINDA
                </div>
            </Col>
            <Col span={24} className="about-content">
                <h1>
                    TÜRKİYE DENİZCİLİK ZİRVESİ
                </h1>
                <h2>
                    1-2 Temmuz 2022, Tersane İstanbul
                </h2>
                <h4 style={{overflowX:"auto"}}>
                    Türkiye’nin denizcilik vizyonunu tüm dünyaya aktaracağı, dünya denizcilik trendlerinin ve kalkınmadaki stratejik rolünün işleneceği konseptlerden oluşacak zirvedir.

                    Ulaştırma ve Altyapı Bakanlığımızın vizyonu doğrultusunda sektör paydaşlarının karşılıklı bilgi alışverişinin ve ilişki ağlarının geliştirilmesine katkı sağlamak üzere bu yıl ikinci defa düzenlenecek olan zirvemizde, yerli ve yabancı katılımcıların tek bir çatı altında; oturumlar, deneyimsel uygulamalar, zenginleştirilmiş içerik ve metodolojilerle bir araya getirilmesi hedeflenmektedir.

                </h4>


            </Col>

            <Col span={24} className='action-container'>
               
            </Col>
            <img src={require("../assets/images/sol-ust.svg").default} className="sol-ust-about" alt='sol-ust' />
            <img src={require("../assets/images/sol-alt.svg").default} className="sol-alt-about" alt='sol-alt' />
                <img src={require("../assets/images/sag-ust.svg").default} className="sag-ust-about" alt='sol-alt' />
                <img src={require("../assets/images/sağ-alt.svg").default} className="sag-alt-about" alt='sag-alt' />

        </Row>
    );
}