import { Carousel, Row, Col, Divider } from 'antd';
import React, { useState, useEffect } from 'react';
import ButtonContainer from '../components/ButtonContainer';
import './index.css'
import TripleToggleSwitch from '../components/toggle/triple';
import { Schedular1 } from '../assets/schedular1'
import { Schedular2 } from '../assets/schedular2'
import { Collapse } from 'react-collapse';
export default function Program({ history }) {

    const [day, setDay] = useState("left");
    const [schedular1, setSchedular1] = useState(Schedular1);
    const [schedular2, setSchedular2] = useState(Schedular2);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const labels = {
        left: {
            title: "1 TEMMUZ 2022",
            value: "left"
        },
        right: {
            title: "2 TEMMUZ 2022",
            value: "right"
        }
    };
    const onChange = (value) => setDay(value)
    const onOpen = (item) => {
        var lst = [...schedular1]
        if (day != "left") {
            lst = [...schedular2]
        }
        var findItem = lst.find(t => t.id == item.id);
        if (findItem.opened) {
            delete item.opened
        } else {
            item.opened = true;
        }
        if (day != "left") {
            setSchedular2(lst)
        } else {
            setSchedular1(lst)
        }

    }

    const renderSchedular = (item) => {

        return <Row>
            <Col span={5} className="scheduler-time">
                <div>{item.time}</div>
            </Col>
            <Col span={19} className="scheduler-header">
                <Row>
                    <Col span={22}>
                        <div onClick={() => {

                            onOpen(item)
                        }}>{item.header}</div>
                        <Collapse isOpened={item.opened} >
                            <h5 className='scheduler-desc'>{item.description}</h5>
                            {item.titles ? <> <div className='scheduler-subheader'>Bu kapsamda oturumda konuşulması beklenen konu başlıkları:</div>
                                {item.titles.map(x => <h5 className='titles'> <li>{x}</li> </h5>)}</> : null}

                            {item.persons ? <> <Divider>

                            </Divider> <Row className='padding2' type="flex">
                                    {item.persons.map(x =>
                                        <Col md={x.moderator || item.specialperson ? 24 : 12} xl={x.moderator || item.specialperson ? 24 : 12} sm={24} xs={24} >



                                            {x.moderator ? <> <h5 className='persone_name' > Moderatör </h5>
                                                <h5 className='persone_name' >

                                                    {x.name}

                                                </h5> </> :
                                                <h5> <li className='persone_name' >

                                                    {x.name} </li>

                                                </h5>}

                                            <h5 className='persone_title'> {x.title}  </h5>
                                            <Divider></Divider>
                                        </Col>
                                    )}

                                </Row></> : null}
                        </Collapse>
                    </Col>
                    <Col span={2}>
                        {item.description || item.persons ? <img src={require("../assets/images/collapse-icon.png")}
                            className={item.opened ? "collapse-icon" : "collapse-rotate-180 collapse-icon"}
                            onClick={() => {

                                onOpen(item)
                            }}
                            alt='collapse-icon' /> : null}
                    </Col>
                </Row>
            </Col >
        </Row >
    }

    return (
        <Row type="flex" >
            <Col span={24} >
                <img src={require("../assets/images/banner.png")} className="header-banner" alt='header-banner' />
                <div className="banner-content">
                    PROGRAM
                </div>
            </Col>
            <Col span={24} style={{ zIndex: 100 }}>
                <TripleToggleSwitch labels={labels} onChange={onChange} />
            </Col>
            <Col span={24} className="schedular" >
                {day == "left" ? schedular1.map(item => renderSchedular(item)) :
                    schedular2.map(item => renderSchedular(item))}
            </Col>

            <Col span={24} className='action-container'>
                {/* <ButtonContainer history={history}>

                </ButtonContainer> */}
            </Col>


        </Row>
    );
}