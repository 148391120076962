import { Carousel, Row, Col } from 'antd';
import React, { useEffect } from 'react';
import ButtonContainer from '../components/ButtonContainer';
import './index.css'
import { SpeakerList } from '../assets/speakrs'
export default function Speakers({ history }) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const speakerItem = (item) => {
        if (item.reis) {
            return <Col md={24} xl={24} sm={24} xs={24} className="speaker-item reis" >
                <Col span={24} className="speaker-item-container" >
                    <img src={item.image} className="speaker-image" />

                    <div className='spekars-footer'>
                        <div className='speaker-name' style={{paddingTop:0}}>{item.name}</div>
                        <div className='speaker-title' >{item.title}</div>
                    </div>
                </Col>
            </Col>
        } return <Col md={8} xl={6} sm={12} xs={12} className="speaker-item">
            <Col span={24} className="speaker-item-container">
                <img src={item.image} className="speaker-image" />

                <div className='spekars-footer'>
                    <div className='speaker-name'>{item.name}</div>
                    <div className='speaker-title' >{item.title}</div>
                </div>
            </Col>
        </Col>

    }

    return (
        <Row type="flex" >

            <Col span={24} >
                <img src={require("../assets/images/banner.png")} className="header-banner" alt='header-banner' />
                <div className="banner-content">
                    KONUŞMACILAR
                </div>
            </Col>
            <Col span={24} className="about-content">
                <Row className='speaker-main-content' type="flex" >
                    {SpeakerList.filter(t => t.visible != false).map(x =>
                        speakerItem(x)
                    )}
                </Row>
                {/* <Col span={24} className="speakers-content">
                    <div>
                        Konuşmacılar Yakında Açıklanacaktır.
                    </div>
                    <img src={require("../assets/images/speakers.png")} className="speakers-banner" alt='speakers-banner' />

                </Col> */}


            </Col>

            <Col span={24} className='action-container'>
                {/* <ButtonContainer history={history}>

                </ButtonContainer> */}
            </Col>


        </Row>
    );
}