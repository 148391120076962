import { Row, Col } from 'antd';
import React, { useEffect } from 'react';
import ButtonContainer from '../components/ButtonContainer';
import './index.css'
import MapWrapper from '../components/MapWrapper'
export default function Contact({ history }) {


    useEffect(() => {
        window.scrollTo(0, 0);;
    }, []);
    return (
        <Row type="flex" >

            <Col span={24} >
                <img src={require("../assets/images/banner.png")} className="header-banner" alt='header-banner' />
                <div className="banner-content">
                    İLETİŞİM
                </div>
            </Col>

            <Col span={24}  style={{paddingTop:"4%"}}>
                <div className='contact-text-ust'>
                    <strong>   Davetiye talebiniz için bize ulaşabilirsiniz.</strong>
                </div >
                <div className='contact-text-ust'>
                    zirve.dgm@uab.gov.tr
                </div >
                <div className='contact-text-ust'>
                    0537 640 9039
                </div>

            </Col>
            <Col span={24} className="about-content" style={{ heigth: "50%" ,marginTop:"2%"}}>

                <MapWrapper disabledClick inContent markers={[{
                    lat: 41.03801, lng: 28.95198,
                    tooltip: "2",
                    id: 1
                }]} />


            </Col>

            <Col span={24} className='action-container'>
                <div className='contact-text'>
                    Kasımpaşa, Tersane İstanbul, Haliç Camii Kebir Mahallesi Taşkızak, Tersane Cd. No:5, 34440 Beyoğlu/İstanbul
                </div>



            </Col>


        </Row>
    );
}