
export const Schedular2 = [
    {
        "id": 1,
        "time": "10:00 – 10:30",
        "header": "KEYNOTE",
        "header_color": "red",
        "persons": [{
            name: "Cleopatra Doumbia – HENRY",
            title: "Dünya Denizcilik Üniversitesi Başkanı "
        }
        ]
    },
    {
        "id": 2,
        "time": "10:30 – 12:30",
        "header": "GEMİ İNSANI İSTİHDAMI: MLC SÖZLEŞMESİNİN ETKİLERİ",
        "description": "MLC 2006 Sözleşmesine Ülkemiz 26 Mart 2017 tarihinde taraf olmuş ve bu kapsamdaki mevzuat uyumlaştırma çalışmaları sürdürülmektedir. Sözleşme toplam gemi tonajının %33’üne sahip 30 ülkenin onayından 12 ay sonra, bu şartı sağlayan Üye Devletler için yürürlüğe girmiştir (Article VIII, 3). Gemi adamlarına yönelik kapsamlı ilke ve haklar içeren Sözleşme, üç ayrı fakat birbiri ile ilgili bölümlerden oluşmaktadır. 16 madde ile Kurallar ve Kod bölümlerini içeren Sözleşme; gemi adamlarının gemideki yaşam koşullarını ve yaşam mahallerine ilişkin hükümlerin yanında gemi adamlarının kişisel haklar ve yasal çözüm yolları hususunda daha iyi bilgilendirilmeleri ve her düzeyde gelişmiş bir sosyal diyalog tesis edilmesini sağlamaktadır.",
        "titles": ["Gemi İnsanı Yaşam Koşulları",
            "Gemi İnsanı Hakları",
            "İş Sağlığı ve Güvenliği",
            "MLC Sözleşme Uygulama Alanları",
            "Uluslararası Sözleşmeler",
            "Sözleşmenin Türk Hukukuna Etkisi"],
        "persons": [{
            name: "Prof. Dr. Ersan BAŞAR",
            title: "Karadeniz Teknik Üniversitesi, Akademisyen",
            moderator:true
        },
        {
            name: "Ünal BAYLAN",
            title: "Denizcilik Genel Müdürü "
        },
        {
            name: "Nurcan ÖNDER",
            title: "Çalışma Genel Müdürü"
        },
        {
            name: "İsmail ERTÜZÜN",
            title: "Emeklilik Hizmetleri Genel Müdürü"
        },
        {
            name: "Tamer KIRAN",
            title: "Deniz Ticaret Odası Yönetim Kurulu Başkanı"
        },
        {
            name: "Ali İbrahim KONTAYTEKİN",
            title: "TAİS Başkanı"
        },
        {
            name: "İrfan METE",
            title: "Türkiye Denizciler Sendikası Başkanı "
        }
        ]

    },
    {
        "id": 3,
        "time": "12:30 – 14:00",
        "header": "ÖĞLE ARASI",
    },
    {
        "id": 4,
        "time": "14:00 – 14:30",
        "header": "KEYNOTE",
        "header_color": "red",
        "persons": [{
            name: "Yüksel YILDIRIM",
            title: "Yıldırım Şirketler Grubu İcra Kurulu Başkanı"
        }
        ]
    },
    {
        "id": 5,
        "time": "14:30 – 16:00",
        "header": "LOJİSTİK ODAĞINDA DENİZ YAPILARI ALTYAPISI",
        "description": "Ülkemiz kıyıları adalar dışında 8.333 km uzunluğunda kıyısı ve her biri farklı ekolojik özelliklere sahip Karadeniz, Akdeniz, Marmara ve Ege denizleri ile çevrili bir yarım ada olması sebebiyle farklı işlevlerde ve faal olarak liman ve iskele tesisi, marina/yat limanı/yanaşma yeri, tersane, tekne imal yeri, çekek yeri, balıkçılık kıyı yapısı ve gemi söküm tesisi olmak üzere 2020 yılı itibariyle toplam 951 adet kıyı yapısı yer almaktadır. Bahse konu kıyı yapılarının 217 tanesinin işletme izni veya geçici işletme izni bulunmaktadır. 2020 yılı itibariyle, üst ölçekte; Ulusal Ulaştırma Ana Planı çalışmaları başlatılmış, 11.Kalkınma Planı ile küçük ölçekli, dağınık, verimsiz ve hizmet kalitesi düşük liman altyapısının önüne geçilmesini teminen “Liman Yatırım Otoritesi” oluşturulması çalışmalarına başlandığı bilinmektedir.",
        "titles": ["Türkiye Tersanaleri ve Kapasiteleri",
            "Türkiye Deniz Yapıları Projeleri",
            "Deniz Yapıları Trendleri",
            "Deniz Araçları Projeleri",
            "Deniz Yapıları Tasarımı ve Uygulamaları"],
        "persons": [{
            name: "Prof. Dr. Soner ESMER",
            title: "Dokuz Eylül Üniversitesi, Akademisyen",
            moderator:true
        },
        {
            name: "Dr. Yalçın EYİGÜN",
            title: "AYGM Genel Müdürü"
        },
        {
            name: "Salih TAN",
            title: "Tersaneler ve Kıyı Yapıları Genel Müdürü"
        },
        {
            name: "Aydın ERDEMİR",
            title: "TURKLİM Başkanı"
        },
        {
            name: "Şadan KAPTANOĞLU",
            title: "TURMEPA Başkanı, İMEAK DTO Başkan Yardımcısı"
        },
        {
            name: "Osman NURETTİN PAKSU",
            title: "TERSAN Yönetim Kurulu Başkanı"
        },
        {
            name: "Birol ÜNER",
            title: "GEMAK CEO"
        }
        ]

    },
    {
        "id": 5,
        "time": "16:00 – 16:30",
        "header": "ARA",
    },
    {
        "id": 8,
        "time": "16:30 – 18:30",
        "header": "JEOPOLİTİK GELİŞMELER VE MAVİ VATAN",
        "description": "Türkiye, 8400 km’den fazla doğal kıyı uzunluğu ile Avrupa ve Asya ortasındaki jeopolitik konumuyla Doğu-Batı ve Kuzey-Güney koridorları arasında büyük bir ticaret güzergâhının kalbi durumundadır. “Mavi Vatan” ile deniz sınırından doğan hak ve menfaatler önemlidir. Dünya ekonomisinde Doğu Akdeniz bölgesi; deniz ticaretinin %30’u, petrol trafiğinin %20’si, Rus Dış Ticareti’nin %65’i ve Türk Dış Ticareti’nin %80’ine katkı sunmakta ve jeopolitik önem arz etmektedir. Bölgenin, ticaret hacmi ve enerji arzı çerçevesinde artan önemi; Türk jeopolitiğinde Seville haritası, Suriye iç savaşı ve güç boşluğundan doğan terör tehdidi, Filistin bölgesinde yaşanan çatışmalar ve Libya’daki istikrarsız düzen nedeniyle güvenlik endişesi oluşturmaktadır. Doğu Akdeniz ve Kıbrıs çevresinde de güvenlik ihtiyacı artmıştır. Türkiye “Mavi Vatanın” güvenliği ve çıkarlarını korumak için deniz jeopolitiğini siyaset üstü anlayışla takip etmek, deniz gücü ve denizcilik gücünü geliştirecek politikalar üretmek önemli yaklaşımlardır.",
        "titles": ["Savunma Sanayi’ndeki Deniz Araçları Projeleri",
            "Denizcilikte Siber Güvenlik",
            "Deniz Suçları",
            "Stratejik İş Birlikleri",
            "Deniz Güvenliği",
            "Savunma Sanayi Hedefleri",
            "Anlaşmalar ve Sözleşmeler",
            "Uluslararası Güvenlik Politikaları"],
        "persons": [{
            name: "Prof. Dr. Oral ERDOĞAN",
            title: "Piri Reis Üniversitesi Rektörü",
            moderator:true
        },
        {
            name: "Selim Dursun",
            title: "T.C. Ulaştırma ve Altyapı Bakan Yardımcısı"
        },
        {
            name: "Mehmet Emin BİRPINAR",
            title: "T.C. Çevre, Şehircilik ve İklim Değişikliği Bakan Yardımcısı"
        },
        {
            name: "Yavuz Selim KIRAN",
            title: "T.C. Dışişleri Bakan Yardımcısı"
        },
        {
            name: "Dr. Alparslan BAYRAKTAR",
            title: "T.C. Enerji ve Tabii Kaynaklar Bakan Yardımcısı"
        },
        {
            name: "Mehmet Fatih KACIR",
            title: "T.C. Sanayi ve Teknoloji Bakan Yardımcısı"
        },
            // {
            //     name: "Oramiral Adnan ÖZBAL",
            //     title: "Deniz Kuvvetleri Komutanı"
            // },
            // {
            //     name: "Prof. Dr. İsmail DEMİR",
            //     title: "T.C. Cumhurbaşkanlığı Savunma Sanayii Başkanı "
            // }
        ]
    },
    {
        "id": 9,
        "time": "18:30 – 19:00",
        "header": "ARA",
    },
    {
        "id": 10,
        "time": "19:00 – 19:30",
        "header": "KAPANIŞ",
        "persons": [{
            name: "Kitack LIM",
            title: "IMO Genel Sekreteri"
        }
        ]
    },
    {
        "time": "19:30 – 20:00",
        "header": "ÖZEL OTURUM",
        "header_color": "red",
        "persons": [{
            name: "Adil KARAİSMAİLOĞLU",
            title: "T.C. Ulaştırma ve Altyapı Bakanı"
        }
        ]
    },
]