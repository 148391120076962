import { Carousel, Row, Col } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom'
import './index.css'
export default function ButtonContainer(props) {

    let navigate = useNavigate();
    const redirectAboutUs = () => {

        navigate('/aboutus');
    }

    const redirectProgram = () => {

        navigate('/program');
    }
    const redirectSpeakers = () => {

        navigate('/speakers');
    }
    const redirectExperience = () => {

        navigate('/experience');
    }
    return (<Row>
        <Col md={12} xl={6} sm={12} xs={12} className={props.main ? " btn-container" : "btn-container btn-container-little "} onClick={() => { redirectAboutUs() }}>
            <img src={require("../assets/images/hakkinda-kule.svg").default} className="hakkinda-kule" alt='hakkinda-kule' />
            <div className='hakkinda-footer'>

            </div>
            <img src={require("../assets/images/hakkinda-gemi.svg").default} className="hakkinda-gemi" alt='hakkinda-gemi' />

            <img src={require("../assets/images/hakkinda-marti-1.svg").default} className="hakkinda-marti-1" alt='hakkinda-marti-1' />
            <img src={require("../assets/images/hakkinda-marti-2.svg").default} className="hakkinda-marti-2" alt='hakkinda-marti-2' />
            <img src={require("../assets/images/hakkinda-marti-3.svg").default} className="hakkinda-marti-3" alt='hakkinda-marti-3' />
            <div className='action-text hakkinda'>
                Hakkında

            </div>


        </Col>
        <Col md={12} xl={6} sm={12} xs={12}className={props.main ? " btn-container" : "btn-container btn-container-little "} onClick={() => { redirectProgram() }}>
            <img src={require("../assets/images/dumen-kenar.svg").default} className="dumen-kenar" alt='dumen-kenar' />

            <img src={require("../assets/images/dumen.svg").default} className="dumen" alt='dumen' />
            <div className='action-text program'>
                Program

            </div>

        </Col>
        <Col md={12} xl={6} sm={12} xs={12} className={props.main ? " btn-container" : "btn-container btn-container-little "} onClick={() => { redirectSpeakers() }}>
            <img src={require("../assets/images/speakers-left.svg").default} className="speakers-left" alt='speakers-left' />

            <img src={require("../assets/images/speaker-table.svg").default} className="speaker-table" alt='speaker-table' />
            <div className='action-text speakers'>
                Konuşmacılar

            </div>
        </Col>
        <Col md={12} xl={6} sm={12} xs={12} className={props.main ? " btn-container" : "btn-container btn-container-little "}  onClick={() => { redirectExperience() }}>
            <img src={require("../assets/images/capa.svg").default} className="capa" alt='capa' />

            <img src={require("../assets/images/dalga.svg").default} className="dalga-1" alt='dalga-1' />
            <img src={require("../assets/images/dalga.svg").default} className="dalga-2" alt='dalga-2' />
            <div className='deneyim-footer'>

            </div>
            <div className='action-text deneyim'>
                Deneyim

            </div>
        </Col>
    </Row>
    );
}