import { Carousel, Row, Col } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import ButtonContainer from '../components/ButtonContainer';
import { Modal } from 'antd'
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import './index.css'

export default function Main() {
    const ref = useRef(null);
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Row type="flex" >
            <Col span={24} className='carousel-container'>
                <Carousel className='carousel' autoplay={!visible} >
                    <div className='carousel-item  ' >

                        <div className='carousel-item-content banner5' >

                            <h2>Türkiye Denizcilik Zirvesi 2021'e Genel Bakış</h2>
                            <div style={{
                                textAlign: "center", alignContent: "center",
                                justifyContent: "center", alignItem: "center"
                                , display: "flex"
                            }}>
                                <img className='play-icon' onClick={() => {
                                    setVisible(true);
                                    setTimeout(() => {
                                        ref.current.videoEl.currentTime = 0;
                                        ref.current.videoEl.play()
                                    }, 1000)
                                }}
                                    src={require('../assets/images/PLAY.svg').default} />
                            </div>
                        </div>
                    </div>
                    <div className='carousel-item  '>
                        <div className='carousel-item-content banner3 '>

                            <h2>Türkiye Denizcilik Zirvesi</h2>
                            <h4>1-2 Temmuz 2022, Tersane İstanbul</h4>
                            <h5 >Ulaştırma ve Altyapı Bakanlığımızın vizyonu doğrultusunda sektör paydaşlarının karşılıklı bilgi alışverişinin ve ilişki ağlarının geliştirilmesine katkı sağlamak üzere bu yıl ikinci defa düzenlenecektir.
                            </h5>

                        </div>
                    </div >
                    <div className='carousel-item '>
                        <Col xs={0} sm={0} md={24} xl={24} className='banner1'>
                        </Col>
                        <Col xs={24} sm={24} md={0} xl={0} className='banner1-mobile'>
                        </Col>
                    </div>
                    <div className='carousel-item  '>

                        <div className='carousel-item-content banner2'>

                            <h2 className='carousel-item-mobile'>Fuaye Alanında Deneyimler Sizi Bekliyor..</h2>
                            {/* <h4>Fuaiye Alanında Deneyimler Sizi Bekliyor..</h4> */}
                            <h5 className='carousel-extra'> Deneyim alanında sizleri odak gücünüzü test edip, denizin serin sularında sürat teknesiyle turlamaya davet ediyoruz.</h5>

                        </div>
                    </div>
                    <div className='carousel-item  ' >

                        <div className='carousel-item-content banner4'>

                            <h2>Tersane İstanbul</h2>
                            <h5 className='carousel-extra' >Türkiye Denizcilik Zirvesi; yaklaşık 6 asırdır gözlerden uzak kalan tersane bölgesini,  tarihi Osmanlı donanma tersanelerini yeniden İstanbul’a taşıyan alanda Tersane İstanbul’da gerçekleştirilecektir.</h5>

                        </div>
                    </div>

                </Carousel>
            </Col>
            <Col span={24} className='action-container'>
                <ButtonContainer main>

                </ButtonContainer>
            </Col>

            <Modal visible={visible} footer={null} width={960}

                onCancel={() => { setVisible(false); ref.current.videoEl.pause() }} >
                <Video loop
                    ref={ref}
                    controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                    onCanPlayThrough={() => {
                        // Do stuff
                    }}>
                    <source src={require("../assets/video.mp4")} type="video/mp4" />
                    <track label="English" kind="subtitles" srcLang="en" src="http://source.vtt" default />
                </Video>

            </Modal>
        </Row>
    );
}